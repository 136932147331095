// src/components/IconFetcher.tsx
import React, { useState, useEffect } from 'react'
import { Avatar, Spinner } from '@nextui-org/react'
import GaiaLogo from '@components/basic/logo/GaiaLogo'
import useIconStore from '@/states/iconStore'

export type EntityType = 'Assistant' | 'Tool' | 'KnowledgeContainer' | 'Team'

interface Props {
  entityId: string | undefined
  entityType: EntityType
  imageId?: string | undefined
}

const IconFetcher: React.FC<Props> = ({ entityId, entityType, imageId }) => {
  const [iconUrl, setIconUrl] = useState<string>('')
  const [loading, setLoading] = useState<boolean>(true)
  const getIcon = useIconStore((state) => state.getIcon)

  useEffect(() => {
    const fetchIconUrl = async () => {
      setLoading(true)
      if (entityId && imageId && isGuid(imageId)) {
        try {
          const url = await getIcon(entityId, entityType, imageId)
          setIconUrl(url)
        } catch (error) {
          console.error('Error fetching icon URL:', error)
          setIconUrl('')
        }
      } else if (imageId && isHttpUrl(imageId)) {
        setIconUrl(imageId)
      } else {
        setIconUrl('')
      }
      setLoading(false)
    }

    fetchIconUrl()
  }, [entityId, entityType, imageId, getIcon])

  if (loading) {
    return <Spinner size="md" />
  }

  return (
    <Avatar
      src={iconUrl}
      color="primary"
      className="min-w-10 min-h-10"
      fallback={<GaiaLogo opposite className="w-6 h-6" />}
    />
  )
}
function isGuid(s: string): boolean {
  const guidRegex =
    /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i
  return guidRegex.test(s)
}

function isHttpUrl(s: string): boolean {
  const urlRegex = /^https?:\/\/.+/i
  return urlRegex.test(s)
}

export default IconFetcher
