// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck fix errors
//TODO fix ts errors
import React, { useEffect, useState, useCallback } from 'react'
import { Autocomplete, AutocompleteItem, Spinner } from '@nextui-org/react'
import { useInfiniteScroll } from '@nextui-org/use-infinite-scroll'
import { useDebouncedCallback } from 'use-debounce'
import { iItem } from '@/interfaces/iItem.ts'
import ItemSelectionItem from '@components/tools/ItemSelectionItem.tsx'
import { EntityType } from '@components/basic/icon/IconFetcher.tsx'

type ItemSelectionProps<T> = {
  selectedItems: T[]
  label: string
  placeholder: string
  onChange: (selectedItems: T[]) => Promise<void>
  onClickItem?: (item: T) => void
  isDisabled?: boolean
  singleSelection?: boolean
  loadFunc: ({
    limit,
    offset,
    searchValue,
  }: {
    limit: number
    offset: number
    searchValue?: string
  }) => Promise<{ data: T[]; total: number }>
}

const ItemSelection = <T extends iItem>({
  selectedItems,
  onChange,
  loadFunc,
  label,
  placeholder,
  onClickItem,
  isDisabled,
  singleSelection,
}: ItemSelectionProps<T>) => {
  const [search, setSearch] = useState('')
  const [items, setItems] = useState<T[]>(selectedItems)
  const [allItems, setAllItems] = useState<T[]>([])
  const [hasMore, setHasMore] = useState(true)
  const [loading, setLoading] = useState<boolean>(false)
  const [offset, setOffset] = useState<number>(0)
  const [isAutocompleteActive, setIsAutocompleteActive] = useState(false)
  const [, scrollerRef] = useInfiniteScroll({
    hasMore,
    isEnabled: isAutocompleteActive,
    shouldUseLoader: false,
    onLoadMore: () => {
      loadItems()
    },
  })
  useEffect(() => {
    setItems(selectedItems)
  }, [selectedItems])

  const loadItems = async (options?: { clear?: boolean; searchValue?: string }) => {
    setLoading(true)
    let tmpOffset = offset
    let tmpAllItems = allItems
    if (options?.clear) {
      tmpOffset = 0
      tmpAllItems = []
    }
    return loadFunc({
      limit: 5,
      offset: tmpOffset,
      searchValue: options?.searchValue,
    })
      .then((response) => {
        if (
          response.total <= tmpAllItems.length + response.data.length ||
          !response.data.length
        )
          setHasMore(false)
        setAllItems([...tmpAllItems, ...response.data])
        setOffset(offset + 5)
      })
      .finally(() => {
        setLoading(false)
      })
  }
  useEffect(() => {
    if (isAutocompleteActive && !allItems.length && !loading)
      loadItems({ clear: true })
  }, [isAutocompleteActive])

  const onSelectionChange = useCallback(
    async (index: number) => {
      if (index < 0) return
      allItems[index]._isLoading = true
      setAllItems([...allItems])
      const params = singleSelection
        ? [allItems[index]]
        : [...selectedItems, allItems[index]]
      onChange(params).then(() => {
        allItems[index]._isLoading = false
        setAllItems([...allItems])
      })
    },
    [allItems, onChange, items],
  )

  const deleteItemFromList = async (item: T, index: number) => {
    items[index]._isDeleting = true
    setItems([...items])
    onChange(items.filter((tool) => tool.id !== item.id)).then(() => {
      setAllItems([...allItems])
    })
  }
  function getEntityType(item: T): EntityType {
    if ('title' in item) return 'Assistant'
    if ('functionName' in item) return 'Tool'
    if ('budget' in item) return 'Team'
    return 'KnowledgeContainer'
  }
  // Debounce callback
  const debounced = useDebouncedCallback(
    // function
    (value) => {
      loadItems({ clear: true, searchValue: value })
    },
    // delay in ms
    800,
  )
  return (
    <div className="flex flex-col w-full min-w-fit">
      <Autocomplete
        isDisabled={isDisabled}
        variant=""
        isLoading={loading}
        items={allItems}
        label={label}
        placeholder={placeholder}
        scrollRef={scrollerRef}
        onOpenChange={setIsAutocompleteActive}
        selectedKey={null}
        inputValue={search}
        listboxProps={{
          emptyContent: <>{!loading && 'No results found'}</>,
          bottomContent: (
            <div className="flex justify-center min-h-9">
              {loading && <Spinner />}
            </div>
          ),
        }}
        onSelectionChange={(t) => {
          setSearch('')
          const toolIndex = allItems.findIndex((tool) => tool.id! === t)
          onSelectionChange(toolIndex)
        }}
        onInputChange={(value) => {
          debounced(value)
          setSearch(value)
        }}
        children={allItems.map((item) => {
          return (
            <AutocompleteItem
              key={item.id!}
              isDisabled={!!items.find((tool) => tool.id === item.id)}
              textValue={item.name}
            >
              <ItemSelectionItem
                entityId={item.id!}
                entityType={getEntityType(item)}
                name={item.name ?? item.title}
                isDisabled={isDisabled}
                owner={item.ownerName || item.ownerEmail}
                description={item.description}
                image={item.image}
                isLoading={item._isLoading}
              ></ItemSelectionItem>
            </AutocompleteItem>
          )
        })}
      ></Autocomplete>
      <div className="mt-4">
        {items.map((item, index) => (
          <div key={item?.id} className="flex flex-1 items-center mb-3">
            <ItemSelectionItem
              entityId={item.id!}
              entityType={getEntityType(item)}
              name={item.name ?? item.title}
              isDisabled={isDisabled}
              owner={item.ownerName || item.ownerEmail}
              description={item.description}
              image={item.image}
              isDeleting={item._isDeleting}
              onClickDelete={() => deleteItemFromList(item, index)}
              onClick={() => onClickItem(item)}
            ></ItemSelectionItem>
          </div>
        ))}
      </div>
    </div>
  )
}

export default React.memo(ItemSelection)
